import React, { useEffect, useState, useRef } from "react";
import Header from "components/Header";
import {
  Input,
  Table,
  Modal,
  Tag,
  Row,
  Col,
  Form,
  Select,
  Switch,
} from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import sound from "../../assets/notification.mp3";
import arrowBack from "../../assets/arrow.svg";
import shipperIcon from "../../assets/shipping_icon.svg";
import { firebaseMessaging } from "src/firebaseInit";
import { useHistory } from "react-router-dom";
import { customNotification } from "utils/CommomFunction";
import Notification from "components/Notification";
import {
  apiGetListOrder,
  apiGetAllStoreInTheArea,
} from "apis/Order";
import { apiGetShipperList, enableShipper, merchantAcceptShipping, apiCreateShipper, apiUpdateShipper } from "apis/Shipper";
import { apiGetNotificationList } from "apis/Notification";
import { renderFooterShipper } from "./render";
import axios from "axios";
import { CustomInput } from "components";
import { isEmpty } from "lodash";

const { confirm } = Modal;
const { Option } = Select;
const ShipperPage = () => {
  const [shipperList, setShipperList] = useState([]);
  const [shipperListFilter, setShipperListFilter] = useState([]);
  const [shipperDetail, setShipperDetail] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [dataOrderModal, setDataOrderModal] = useState();
  const [storeList, setStoreList] = useState();
  const [orderList, setOrderList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [shipperName, setShipperName] = useState();
  const history = useHistory();
  const [reload, set_reload] = useState(false);
  const loopPlayAudio = useRef();
  const playAudio = useRef();
  const [form] = Form.useForm();
  var blurred = false;

  const windowHeight = window.innerHeight;

  const renderStatus = (
    shipperDetail,
    style = { padding: "5px 25px", borderRadius: "5px", fontWeight: "bold" }
  ) => {
    let color = "#0A8D87",
      content = "ACTIVE";
    if (shipperDetail?.accept_order === 0) {
      color = "#9F9E9F";
      content = "INACTIVE"
    }
    switch (shipperDetail?.status) {
      case 0:
        color = "#E31837";
        content = "DISABLE";
        break;
    }
    return (
      <Tag color={color} style={style}>
        {content}
      </Tag>
    );
  };
  const columns = [
    {
      title: "MÃ NHÂN VIÊN",
      dataIndex: "user_name",
      width: 150,
      render: (user_name) => <div>{user_name}</div>,
    },
    {
      title: "HỌ VÀ TÊN",
      dataIndex: "last_name",
      align: "center",
      width: 150,
      render: (time, record) => (
        <div>
          {record.last_name} {record.first_name}
        </div>
      ),
    },
    {
      title: "ĐIỆN THOẠI",
      dataIndex: "phone",
      align: "center",
      width: 200,
      render: (phone) => <div style={{ textAlign: "center" }}>{phone}</div>,
    },
    {
      title: "ĐANG GIAO",
      dataIndex: "orders",
      align: "left",
      width: 50,
      render: (orders) => {
        let order = orderList?.find(
          (order) => order.order_number === orders[0]?.order_number
        );
        if (order) {
          return <div>{`${order?.order_number}`}</div>;
        }
      },
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status, record) => renderStatus(record),
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: 50,
      render: (status, record) => (
        <div
          key={1}
          className="view-more"
          onClick={() => {
            let shipper = shipperList.find(
              (shipper) => shipper.id === record.id
            );
            setShipperDetail(shipper);
            setDataOrderModal(record);
            setIsShowModal(true);
          }}
        >
          Chỉnh sửa
        </div>
      ),
    },
  ];

  const handleAcceptShipping = (id, type) => {
    //set type
    type = type === 1 ? 0 : 1;
    merchantAcceptShipping({ id, type }).then((res) => {
      if (res.data && !res.error) {
        setIsLoading(true);
        apiGetShipperList().then((res) => {
          if (res.data && !res.error) {
            setIsLoading(false);
            setIsShowModal(false);
            setShipperList(res.data.getShipperList.list);
            setShipperListFilter(res.data.getShipperList.list);
          }
        })
      }
    })

  }


  const renderModalForm = () => {
    if (shipperDetail?.last_name?.length > 0) {
      form.setFieldsValue({
        firstname: shipperDetail?.first_name,
        lastname: shipperDetail?.last_name,
        userName: shipperDetail?.user_name,
        phone: shipperDetail?.phone,
        gender: parseInt(shipperDetail?.gender),
        accept_order: shipperDetail?.accept_order
      })
    }
    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitForm}
        autoComplete="off"
        className="modal-form"
        id="shipper-form"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label="Tên"
              rules={[{ required: true, message: "Vui lòng nhập tên" }]}
            >
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastname"
              label="Họ"
              rules={[{ required: true, message: "Vui lòng nhập họ" }]}
            >
              <Input placeholder="Họ" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="gender"
              label="Giới tính"
              rules={[{ required: true, message: "Vui lòng chọn giới tính" }]}
            >
              <Select placeholder="Giới tính">
                <Option value={1}>Nam</Option>
                <Option value={2}>Nữ</Option>
                <Option value={3}>Khác</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="userName"
              label="Mã nhân viên"
              autocomplete="new-password"
              rules={[
                {
                  required: true,
                  min: 7,
                  message: "Mã nhân viên là 1 dãy 7 chữ số",
                },
              ]}

            >
              <Input
                placeholder="Mã nhân viên"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="phone"
              label="Số điện thoại"
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại" },
              ]}
            >
              <Input placeholder="Số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        {shipperDetail?.last_name?.length > 0 && (
          <Row>
            <Col span={24}  >
              <Form.Item name="status" label="Chuyển cửa hàng">
                <Select placeholder="Chọn cửa hàng">
                  {storeList.map((store, index) => <Option value={store.id} key={index}>{store.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Mật khẩu mới"
              rules={!shipperDetail ? [
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu',
                },
                {
                  message: 'Mật khẩu phải có ít nhất 7 kí tự gồm số và chữ hoặc số, chữ và kí tự đặc biệt',
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]){0,}[A-Za-z\d@$!%*?&]{8,}$/
                }] : [
                {
                  message: 'Mật khẩu phải có ít nhất 7 kí tự gồm số và chữ hoặc số, chữ và kí tự đặc biệt',
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]){0,}[A-Za-z\d@$!%*?&]{8,}$/
                }
              ]
              }
            >
              <Input.Password placeholder="Mật khẩu" type="password" autocomplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="rePassword"
              label="Xác nhận mật khẩu mới"
              dependencies={['password']}
              hasFeedback
              rules={!shipperDetail ? [
                {
                  required: true,
                  message: 'Vui lòng nhập xác nhận mật khẩu',
                }
                ,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Mật khẩu xác nhận không khớp !');
                  },
                }),
              ] : [
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!isEmpty(getFieldValue('password')) && isEmpty(value)) {
                      return Promise.reject('Vui lòng nhập xác nhận mật khẩu');
                    }
                    return Promise.resolve();
                  },
                }),
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Mật khẩu xác nhận không khớp !');
                  },
                })
              ]}
            >
              <Input.Password placeholder="Xác nhận mật khẩu" />
            </Form.Item>
          </Col>
        </Row>
        {shipperDetail?.last_name?.length > 0 && (
          <Row>
            <Col span={24} offset={14}>
              <Form.Item>
                <span style={{ marginRight: 20, fontWeight: "bold" }}>
                  {" "}
                  Tắt/Bật trạng thái nhận đơn hàng{" "}
                </span>{" "}
                <Switch defaultChecked={shipperDetail?.accept_order === 1} onChange={() => {
                  handleAcceptShipping(shipperDetail?.id, shipperDetail?.accept_order);
                }} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  };

  const renderTitleModal = () => {
    let title = "Thêm Shipper";
    let style = { paddingTop: "0.5px", fontWeight: "bold" };
    if (shipperDetail?.last_name?.length > 0) {
      title = `${shipperDetail?.last_name} ${shipperDetail?.first_name}`;
    }
    return (
      <div key={shipperDetail?.id ? shipperDetail?.id : "1"}>
        <h3 className="title-left">
          {title}{" "}
          {shipperDetail?.last_name?.length > 0 &&
            renderStatus(shipperDetail, style)}
        </h3>
        {shipperDetail?.last_name?.length > 0 && (
          <p style={{ fontWeight: "200", marginTop: "10px" }}>
            Mã nhân viên: {shipperDetail?.user_name}
          </p>
        )}
      </div>
    );
  };


  const handleOkAccountStatus = (shipperId, status) => {
    enableShipper({ shipperId, status }).then((res) => {
      if (!res.errors && res.data) {
        setIsShowModal(false);
        setShipperDetail();
        apiGetShipperList().then((res) => {
          if (!res.errors && res.data) {
            setShipperList(res.data.getShipperList.list);
            setShipperListFilter(res.data.getShipperList.list);
          }
        });
      }
    });
  };

  const handleSubmitForm = () => {

    form.validateFields().then(values => {
      if (!shipperDetail) {
        if (!/^\d+$/.exec(values.userName) || !values.password.includes(values.rePassword)) {
          throw Error;
        }
      }
      if (values.status === undefined) {
        delete values.status;
      }
      if (values?.rePassword) {
        delete values?.rePassword;
      }
      if (shipperDetail) {

        updateShipperInfo(values);
        return;
      } else {
        try {
          setIsLoading(true);
          apiCreateShipper(values).then((res) => {
            callApiGetShipperList();
            setIsLoading(false);
            setIsShowModal(false);
            form.resetFields();
          })
        } catch (error) {
          setIsLoading(false);
          setIsShowModal(false);
          form.resetFields();
        };
      };
    })
      .catch(info => {
      });
  };

  const updateShipperInfo = (values) => {
    try {
      let data = {
        ...values,
        id: shipperDetail?.id,
        ...(values?.status && { restaurant_id: values?.status })
      };

      if (data?.userName) {
        delete data?.userName;
      }

      if (data?.status) {
        delete data?.status;
      };

      const body = {
        "input": { ...data }
      };

      setIsLoading(true);
      apiUpdateShipper(body).then((res) => {
        callApiGetShipperList();
        setIsLoading(false);
        setIsShowModal(false);
        form.resetFields();
      })
    } catch (error) {
      setIsLoading(false);
      setIsShowModal(false);
      form.resetFields();
    };
  };

  const callApiGetShipperList = () => {
    apiGetShipperList().then((res) => {
      setShipperList(res.data.getShipperList.list);
      setShipperListFilter(res.data.getShipperList.list);
    });
  };

  const handleChangeAccountStatus = (shipperDetail) => {
    let content = 'kích hoạt';
    let newStatus = 1;
    switch (shipperDetail?.status) {
      case 1:
        content = 'vô hiệu';
        newStatus = 0;
        break;
    }
    return confirm({
      className: 'account-confirm-modal',
      icon: <></>,
      content: <div className="confirm-account">Có phải bạn muốn {content} tài khoản này?</div>,
      footer: <></>,
      onOk: () => handleOkAccountStatus(shipperDetail?.id, newStatus),
      okText: 'XÁC NHẬN',
      cancelText: <><CloseCircleOutlined style={{ color: '#e31837', fontSize: '16px' }} size={4} /> HỦY</>
    });
  };

  const handleSearch = (e) => {
    let newShipperList = [...shipperList];
    newShipperList = newShipperList.filter(
      (shipper) =>
        shipper.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        shipper.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || shipper.user_name.includes(e.target.value)
    );
    setShipperName(e.target.value);
    setShipperListFilter(newShipperList);
  };

  useEffect(() => {
    firebaseMessaging.onMessage((payload) => {
      setReload();
      set_reload(!reload);
      apiGetNotificationList({ type: "merchant" }).then((res) => {
        if (
          res.data &&
          !res.errors &&
          res.data.notifications.list.length &&
          res.data.notifications.list[0].is_read === 0
        ) {
          customNotification(
            "success",
            <Notification
              title={res.data.notifications.list[0].title}
              content={res.data.notifications.list[0].content}
            />,
            "notification-custom"
          );
        }
      });
    });
    setIsLoading(true);
    apiGetListOrder().then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrders?.orders);
      }
    });
    apiGetShipperList().then((res) => {
      if (!res.errors && res.data) {
        setShipperList(res.data.getShipperList.list);
        setShipperListFilter(res.data.getShipperList.list);
      }
    });
    apiGetAllStoreInTheArea().then((res) => {
      if (!res.error && res.data) {
        axios(res.data.getStoreJsonData.url).then((res) => {
          setStoreList(Object.values(res.data));
        });
      }
    });
    return () => {
      clearInterval(loopPlayAudio.current);
      clearTimeout(playAudio.current);
    };
  }, []);

  window.onblur = function () {
    blurred = true;
  };
  window.onfocus = function () {
    blurred && setReload();
  };
  const setReload = () => { };
  return (
    <div className="shipper-page">
      <Header reload={reload} />
      <div className="container-box">
        <div className="header-bottom">
          <h3 className="header-bottom-left">
            <img src={shipperIcon} alt="icon" />
            SHIPPER
          </h3>
          <div className="header-bottom-right" style={{ justifyContent: "flex-end" }}>
            <div className="search-bar" style={{ width: "50%" }}>
              <CustomInput
                placeholder="Tìm kiếm"
                value={shipperName}
                onChange={handleSearch}
              />
            </div>

            <button className="back-btn" onClick={() => history.push("/home")}>
              {" "}
              <img src={arrowBack} style={{ marginRight: 10 }} />
              QUAY LẠI
            </button>
            <button
              className="back-btn"
              onClick={() => {
                setIsShowModal(true);
                setShipperDetail(null);
              }}
              style={{ backgroundColor: "#E31837" }}
            >
              {" "}
              <PlusCircleOutlined
                style={{ fontSize: "19px", marginRight: "10px" }}
              />
              THÊM SHIPPER
            </button>
          </div>
        </div>
        <Table
          onRow={(record, index) =>
          ({
            onClick: () => {
              let shipper = shipperList.find(
                (shipper) => shipper.id === record.id
              );
              setShipperDetail(shipper);
              setDataOrderModal(record);
              setIsShowModal(true);
            },
          })
          }
          loading={isLoading}
          rowKey="order_number"
          columns={columns}
          dataSource={shipperListFilter}
          className="table-refund"
          pagination={true}
          scroll={{ y: windowHeight - 300 }}
        />
      </div>
      <Modal
        bodyStyle={{
          height: shipperDetail?.last_name?.length > 0 ? "550px" : "none",
        }}
        centered={true}
        onOk={form.submit}
        destroyOnClose={true}
        className="modal-shipper"
        visible={isShowModal}
        onCancel={() => {
          setIsShowModal(false);
          setShipperDetail({});
          form.resetFields();
        }}
        title={renderTitleModal()}
        width={695}
        footer={renderFooterShipper(
          shipperDetail,
          isLoading,
          handleChangeAccountStatus,
          handleSubmitForm
        )}
      >
        {renderModalForm()}
      </Modal>
    </div>
  );
};

export default ShipperPage;
