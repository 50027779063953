import React from "react";
import { Link } from "react-router-dom";
import imgNotFound from "../../assets/notfound.png"
import "./index.scss";
function NotFound(props) {
  return(
    <div className="notfound">
        <img src={imgNotFound} alt=""></img>
        <h3>KHÔNG TÌM THẤY TRANG BẠN YÊU CẦU</h3>
        <button className="button btn-home-back"><Link to="/home">QUAY LẠI TRANG CHỦ</Link></button>
    </div>
  )
}

export default NotFound;
