import React from "react";

function ButtonOnOffSound(props) {
    const { onClick = () => { } } = props;
    return (
        <button className="button-action"
            onClick={onClick}
            style={{ backgroundColor: "red", color: "white", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 8 }}>
            <img src={require('../../assets/bell_off.png')} style={{ width: 17, height: 17, marginRight: 5 }} />
            <p>
                {"TẮT ÂM BÁO"}
            </p>
        </button>);
}
export default ButtonOnOffSound;
