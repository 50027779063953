import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { apiLogin } from "../../apis/Auth";
import { setLocalStorage } from "src/utils/CommomFunction";
import { useHistory } from "react-router-dom";
import logoImage from "../../assets/logo-home.png";
import {
  firebaseMessaging,
} from "src/firebaseInit";
import {
  isContainSpace,
  isContainSpecialCharacters,
  isValidVietnamese,
} from "../../utils/Validation";
import PopupWarningFirebase from "components/Notification/PopupWarningFirebase";

const LoginForm = ({
  checkNotificationPermission = () => { }
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const refPopupWarningFirebase = React.useRef(null);

  useEffect(() => {
    firebaseMessaging.getToken({
      vapidKey:
        "BHvwoHJ48Nr-9gLJqGbrFyve1HSwSxFkSaBIYMeq9T1mQ-8B_H-jDpQp0TvXClPHFrMkkejlDOhkOwrbIFTEM2A",
    }).then(firebaseToken => {
      localStorage.setItem("fcmToken", firebaseToken);
    })
  }, []);

  const onFinish = async(values) => {
    checkNotificationPermission();
    const fcmToken = await localStorage.getItem("fcmToken");
    if(!fcmToken){
      refPopupWarningFirebase?.current?.show?.();
      return;
    };

    setIsLoading(true);
    const dataLogin = { ...values, fcmToken: localStorage.getItem("fcmToken") };
    apiLogin(dataLogin).then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data && res.data.generateStaffToken) {
        setError(false);
        setLocalStorage(
          "accept_order",
          res.data.generateStaffToken.accept_order
        );
        setLocalStorage("address", res.data.generateStaffToken.address);
        setLocalStorage("lastname", res.data.generateStaffToken.lastname);
        setLocalStorage("restaurant", res.data.generateStaffToken.restaurant);
        setLocalStorage("storeId", res.data.generateStaffToken.id);
        setLocalStorage("access_token", res.data.generateStaffToken.token);
        setLocalStorage("weekday", res.data.generateStaffToken.weekday);
        setLocalStorage(
          "store_status",
          res.data.generateStaffToken.store_status
        );
        setLocalStorage("weekend", res.data.generateStaffToken.weekend);
        history.push("/home");
      } else {
        setError(true);
      }
    });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateUsername = (value, field) =>
    isContainSpace(value, field).then((res) =>
      res ? isValidVietnamese(value, field) : ""
    );
  const validatePassword = (value, field) =>
    isContainSpace(value, field).then((res2) =>
      res2 ? isContainSpecialCharacters(value, field) : ""
    );

  return (
    <>
      <Form
        name="basic"
        className="login-form"
        style={{ margin: "none" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="login-top">
          <div className="login-box">
            <div className="login-header">
              <img src={logoImage} height="170px" alt="React Starter" />
              <h2>VUI LÒNG ĐĂNG NHẬP</h2>
            </div>
            <Form.Item name="username" required={false}>
              <Input className="login-input" placeholder="Mã cửa hàng *" />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password className="login-input" placeholder="Mật khẩu *" />
            </Form.Item>
            {error && (
              <div style={{ color: "white" }} className="err">
                <span>Mã cửa hàng hoặc mật khẩu không đúng!</span>
              </div>
            )}
          </div>
        </div>
        <div className="login-bottom">
          <div className="login-box">
            <Form.Item className="login-button-container">
              <Button
                loading={isLoading}
                className="login-button"
                htmlType="submit"
              >
                ĐĂNG NHẬP
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <PopupWarningFirebase ref={refPopupWarningFirebase} />
    </>
  );
};

export default LoginForm;
