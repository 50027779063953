import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal, Radio } from "antd";
import React, { useState, useEffect } from "react";
import ButtonSubmit from "../Button/ButtonSubmit";
import { customNotification } from "src/utils/CommomFunction";
import { apiGetShipperList, apiAssignShipper } from "../../apis/Shipper";

const { info } = Modal;

function ChangeDeliveryStaffModal(props) {
  const {
    closeModalReadyShip,
    isShowModalChangeStaff,
    closeModalChangeStaff,
    dataOrder,
    openCurrentModal,
  } = props;
  const [shipperList, setShipperList] = useState();
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (dataOrder?.shipper_info) {
      setSelectedShipper(dataOrder.shipper_info[0].id);
    }
  }, [dataOrder]);

  useEffect(() => {
    if (isShowModalChangeStaff) {
      apiGetShipperList().then((res) => {
        if (!res.errors && res.data) {
          setShipperList(res.data.getShipperList.list);
        }
      });
    };
  }, [dataOrder, isShowModalChangeStaff])

  const handleConfirm = () => {
    if (selectedShipper) {
      setLoadingButton(true);
      apiAssignShipper({
        shipperId: selectedShipper,
        orderId: dataOrder.id,
      }).then((res) => {
        setLoadingButton(false);
        if (!res.errors && res.data) {
          apiGetShipperList().then((res) => {
            if (!res.errors && res.data) {
              setShipperList(res.data.getShipperList.list);
            }
          });
          customNotification("success", "Đơn hàng đã được thay đổi Shipper");
          closeModalChangeStaff();
          closeModalReadyShip();
          return;
        }
      });
      return;
    }
    closeModalChangeStaff();
    openCurrentModal();
  };

  const renderTitleModal = () => {
    return (
      <>
        <div className="title-left" key={dataOrder?.id}>
          <h3>Thay đổi nhân viên giao hàng</h3>
          <h5>
            Vui lòng nhân viên nhận đơn hàng.
            <span></span>
          </h5>
        </div>
      </>
    );
  };

  const handleSelectShipper = (item) => {
    if (item?.orders?.length >= 2) {
      return info({
        icon: <></>,
        content: <span>Shipper chỉ có thể giao 2 đơn hàng cùng lúc</span>,
        onOk() {
        },
        onCancel() {
        },
      });
    }
    setSelectedShipper(item.id);
  };

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-changeStaff"
      title={renderTitleModal()}
      visible={isShowModalChangeStaff}
      width={560}
      onCancel={closeModalChangeStaff}
      footer={[
        <span onClick={handleConfirm} key={dataOrder?.id}>
          <ButtonSubmit
            loadingButton={loadingButton}
            title=" XÁC NHẬN"
            color="#E31837"
            key={1}
          />
        </span>,
        <button key="huy" className="button-destroy" onClick={() => {
          if (dataOrder?.shipper_info) {
            setSelectedShipper(dataOrder.shipper_info[0].id);
          }
          closeModalChangeStaff();
          openCurrentModal();
        }}>
          <CloseCircleOutlined />
          HỦY
        </button>,
      ]}
    >
      <div className="modal-listStaff">
        {shipperList?.map((item, index) => {
          return (
            <div className="staff-item" key={item?.id} onClick={() => handleSelectShipper(item)}>
              <div className="staff-left">
                <h3>{`${item?.last_name} ${item?.first_name}`}</h3>
                <h5>Tổng đơn đang giao: {item?.orders?.length}</h5>
              </div>
              <span>
                <Radio checked={selectedShipper === item?.id}></Radio>
              </span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default ChangeDeliveryStaffModal;
