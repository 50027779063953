import React from "react";
import { formatPrice, toCommas } from "src/utils/CommomFunction";
export const STATUS_COLUMNS = [
  {
    title: "CHỜ XỬ LÝ",
    status: "pending",
  },
  {
    title: "ĐÃ NHẬN",
    status: "received",
  },
  {
    title: "ĐANG NẤU",
    status: "cooking",
  },
  {
    title: "SẴN SÀNG GIAO",
    status: "ready_to_ship",
  },
  {
    title: "ĐANG GIAO",
    status: "shipping",
  },
];

export const columns = [
  {
    title: "PHẦN ĂN",
    dataIndex: "name",
    width: 380,
    render: (name, item) => {
      return (
        <div className="order-name" style={{
          paddingBottom: item.options.length === 0 && '34px'
        }}>
          <h3 >{name}</h3>
          {item?.options &&
            item?.options.map((itemOption, index) => (
              <div key={index}>
                <h5 className="color-orange">Option</h5>
                <p className="color-green-color-1">
                  {itemOption?.pos_name ?? itemOption?.name} x{itemOption?.qty}
                </p>
                <p className="color-green-color-1">
                  + {formatPrice(itemOption?.price * itemOption?.qty)} đ
                </p>
              </div>
            ))}
        </div>
      );
    },
  },
  {
    title: "GIÁ",
    dataIndex: "price",
    align: "right",
    width: 150,
    render: (price) => <div className="order-price">{formatPrice(price)}</div>,
  },
  {
    title: "SL",
    dataIndex: "count",
    width: 150,
    align: "center",
    render: (count) => <div className="order-count">x{count}</div>,
  },
  {
    title: "THÀNH TIỀN",
    dataIndex: "total",
    align: "right",
    render: (total, item) => <div className="order-price">
      {toCommas(parseFloat(total) * parseInt(item?.count))}đ
    </div>,
  },
];
