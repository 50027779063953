import React from "react";
import { Modal, Button, Typography } from "antd";
import "./index.scss";
import { CloseCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { apiLogout } from "../../apis/Auth";

const { Text } = Typography;

export const ConfirmLogoutModal = (props) => {

  const { isShowConfirmLogout, closeModalConfirmLogout } = props;
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const fcmToken = await localStorage.getItem("fcmToken");
      const data = { fcmToken };
      apiLogout(data).then(() => {
        localStorage.clear();
        history.push("/login");
      });
    } catch (error) {
      localStorage.clear();
      history.push("/login");
    };

  };

  const footer = (
    <div className="modal-button-container" style={{ textAlign: 'center' }}>
      <div className="modal-button-container"></div>
      <Button className="modal-button-confirm" onClick={handleLogout}>
        <div className="modal-text-confirm">XÁC NHẬN</div>
      </Button>
      <div className="modal-button-container"></div>
      <Button
        className="modal-button-back"
        onClick={() => {
          closeModalConfirmLogout();
        }}
      >
        <div className="modal-text-back">
          <CloseCircleOutlined color="#e31837" style={{ fontSize: '20px', marginRight: '10px', fontWeight: "bold" }} />
          <div style={{ fontWeight: "bold", fontSize: '20px' }}>HUỶ</div>
        </div>
      </Button>
      <Text italic type="secondary">Phiên bản: 1.3.3. </Text>
    </div>
  );
  const closeIcon = (
    <span>
      <CloseCircleFilled
        style={{
          color: "#e31837",
          marginLeft: "70px",
          fontSize: 46,
          backgroundColor: "#ffffff",
          borderRadius: 50,
        }}
      />
    </span>
  );
  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      footer={footer}
      className="modal-container modal-logout"
      title={<></>}
      visible={isShowConfirmLogout}
      onCancel={closeModalConfirmLogout}
      closeIcon={closeIcon}
    >
      <div style={{ fontWeight: "bold", fontSize: 25 }}>
        Có phải bạn muốn đăng xuất khỏi ứng dụng?
      </div>
    </Modal>
  );
};
