import { formatPrice } from "utils/CommomFunction";
export const columnRevenue = [
    {
        title: "Phương thức thanh toán",
        dataIndex: "paymentMethod",
        width: 150,
        align: "center",
        render: (paymentMethod, item) => {
            let style = {
                textAlign:'left',
                fontWeight:'400'
            }
            if(paymentMethod === 'Tổng cộng'){
                style.fontWeight = 'bold';
                style.textTransform = 'uppercase';
            }
          return (
            <div style={style}>{paymentMethod}</div>
          );
        },
      },
      {
        title: "Số đơn Hủy",
        dataIndex: "ordersCancelled",
        align: "center",
        render: (ordersCancelled) => <div className="order-price" style={{color:'#FF2129', fontWeight:'bold'}}>{ordersCancelled}</div>,
      },
      {
        title: "Số đơn Boom",
        dataIndex: "ordersBoom",
        align: "center",
        render: (ordersBoom) => <div className="order-count" style={{color:'#FFA437', fontWeight:'bold'}}>{ordersBoom}</div>,
      },
      {
        title: "Số đơn Hoàn thành",
        dataIndex: "ordersCompleted",
        align: "center",
        width: 125,
        render: (ordersCompleted) => <div className="order-price" style={{color:'#A1C89C', fontWeight:'bold'}}>{ordersCompleted}</div>,
      },
      {
        title: "Tổng số đơn",
        dataIndex: "total",
        align: "center",
        render: (total) => <div className="order-price" style={{fontWeight:'bold'}}>{total}</div>,
      },
      {
        title: "Doanh thu",
        dataIndex: "revenue",
        align: "center",
        render: (total) => <div className="order-price" style={{fontWeight:'bold'}}>{formatPrice(total)} đ</div>,
      },
]