export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const isValidVietnamese = (value, field) => {
  const pattern = /[^\x00-\x7F]/;

  if ((pattern.test(value)) && value !== '') {
    return Promise.reject(new Error(`${field} phải là tiếng việt không dấu!`));
  }
  return Promise.resolve(true);
};
export const isContainSpace = (value, field) => {
  if (!value || value.indexOf(' ') === -1) {
    return Promise.resolve(true);
  }

  return Promise.reject(new Error(`${field} không chứa khoảng trắng!`));
};

export const isContainSpecialCharacters = (value, field) => {
  const pattern = /[^\w]/;

  if (!value || !pattern.test(value)) {
    return Promise.resolve(true);
  }

  return Promise.reject(new Error(`${field} không chứa ký tự đặc biệt và khoảng trắng!`));
};

export function isJsonValid(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}
