import { callApi } from "..";


export const apiGetShipperDetail = () => {
  callApi(
    `{
      getShipperInfo {
        id
        firstname
        lastname
        accept_order
        restaurant
        address
      }
    }`,
    null
  )
}

export const apiGetShipperList = () =>
  callApi(
    `{
    getShipperList {
      list {
        id
        status
        user_name
        first_name
        last_name
        gender
        phone
        orders {
          id
          order_number
        }
        accept_order
      }
    }
  }`,
    null
  );

export const apiAssignShipper = (data) =>
  callApi(
    `mutation ($shipperId: Int!, $orderId: Int!){
    assignShipper(shipperId: $shipperId, orderId: $orderId) {
      result
    }
  }`,
    data
  );

export const apiCreateShipper = (data) =>
  callApi(
    `mutation (
    $password: String!
    $firstname: String!
    $lastname: String!
    $userName: String!
    $gender: String!
    $phone: String!
  ) {
    createShipperAccount (
      password: $password
      firstname: $firstname
      lastname: $lastname,
      userName:$userName
      gender:$gender
      phone:$phone
    ) {
      result
    }
  }`,
    data
  );

export const apiUpdateShipper = (data) =>
  callApi(
    `mutation ($input: UpdateShipperInfo!) {
      updateShipperInfo(input: $input) {
        result
      }
    }`,
    data
  );

export const enableShipper = (data) =>
  callApi(
    `mutation ($shipperId: Int!, $status: Int!) {
    enableShipper (shipperId: $shipperId, status: $status) {
      result
    }
  }`,
    data
  );
export const merchantAcceptShipping = (data) =>
  callApi(
    `mutation ($id: Int!, $type: Int!) {
      merchantAcceptShipping(id: $id, type: $type) {
        result
      }
    }`,
    data
  )