export const mockRevenueData = [
    {
      paymentMethod:'Tiền mặt',
      ordersCancelled:7,
      ordersBoom:7,
      ordersCompleted:22,
      total:36,
      revenue:11564000,
    },
    {
      paymentMethod:'Không tiền mặt',
      ordersCancelled:3,
      ordersBoom:5,
      ordersCompleted:6,
      total:14,
      revenue:4663000,
    },
    {
      paymentMethod:'Tổng cộng',
      ordersCancelled: 10,
      ordersBoom:12,
      ordersCompleted:28,
      total:50,
      revenue:16227000,
  
    }
  ]