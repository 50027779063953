import React from 'react';
import { StarFilled } from '@ant-design/icons';
import { Button, Rate } from "antd";
import { isEmpty } from "lodash";

export const renderFooterRefunds = (
  orderDetail,
) => {

  return (
    <>
      <span key={orderDetail?.id}>
        {
          orderDetail?.status !== 'completed' && (orderDetail?.flag_refund == 0 || orderDetail?.flag_refund == -1) &&
          <div style={{ marginRight: '30px' }}>
            <Rate disabled allowHalf defaultValue={orderDetail?.customer_rating} />
            <div style={{ height: '60px', overflowY: 'scroll' }}>
              {orderDetail?.customer_comment}
            </div>
          </div>
        }
        {orderDetail?.flag_refund == 2 &&
          <Button
            key="ĐÃ DUYỆT HOÀN TIỀN"
            style={{ background: '#DFD6E6', cursor: 'not-allowed', marginBottom: 20 }}
            className="btn-submit"
          >
            ĐÃ DUYỆT HOÀN TIỀN
          </Button>
        }
      </span>
      {
        !isEmpty(orderDetail?.reason_cancel) &&
        <div className='blockReasonCancel'>
          <span className='txtReasonCancel'>
            {`Lý do huỷ đơn : `}
          </span>
          <span className='txtReasonCancel'>
            {orderDetail?.reason_cancel}
          </span>
        </div>
      }
    </>
  );
};