import React, { useState } from "react";
import { Modal, Button, Radio, Input } from "antd";
import "./index.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import backIcon from "../../assets/back-button.png";
import { apiCancelOrder, apiApproveRefund, } from "../../apis/Order";
import { customNotification } from "src/utils/CommomFunction";
const { TextArea } = Input;
const { info } = Modal;

export const RejectOrderModal = (props) => {
  const { isShowModalRejectOrder, closeModalRejectOrder, dataOrder, reload, submitRejectOrder, returnModal } =
    props;
  const [selectedRadio, setSelectedRadio] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [comment, setComment] = React.useState(" ");
  const [note, setNote] = useState("");

  const handleCancelOrder = () => {
    if (!selectedRadio) {
      return info({
        icon: <></>,
        content: <span>Vui lòng chọn lý do hủy đơn hàng</span>,
        onOk() {
        },
        onCancel() {
        },
      });
    }
    if (selectedRadio === 3 && note === "") {
      return info({
        icon: <></>,
        content: <span>Vui lòng nhập lý do hủy đơn hàng</span>,
        onOk() {
        },
        onCancel() {
        },
      });
    }

    setLoadingButton(true);


    apiCancelOrder({
      orderId: dataOrder?.id,
      comment: (selectedRadio === 3) ? `${note}` : comment,
    }).then((res) => {

      setLoadingButton(false);

      if(res.errors && res.errors?.length > 0){
        alert(res.errors[0]?.message);
        return;
      }

      if (!res.errors && res.data) {
        if (dataOrder?.payment_method_code !== "cashondelivery") {
          info({
            icon: <></>,
            title: <span style={{ fontWeight: 'bold' }}>Thành công</span>,
            content: 'Hoàn tiền thành công',
            onOk() {
              submitRejectOrder()
              setSelectedRadio(null);
              setNote("");
            },
            onCancel() {
              submitRejectOrder()
              setSelectedRadio(null);
              setNote("");
            },
          });
        } else {
          customNotification(
            "success",
            `Đơn hàng ${dataOrder?.order_number} đã được hủy`
          );
        };
        submitRejectOrder()
        setSelectedRadio(null);
        setNote("");
      }
    });

  };


  const title = (
    <div style={{ borderBottom: "0.5px solid #d3cbcb", paddingBottom: 20 }}>
      <h1>Từ chối đơn hàng #{dataOrder?.order_number}</h1>
      <h2>Vui lòng chọn lý do từ chối đơn hàng.</h2>
    </div>
  );
  const footer = (
    <div className="modal-button-container">
      <div className="modal-button-container pending"></div>
      <Button
        loading={loadingButton}
        className="modal-button-confirm"
        onClick={handleCancelOrder}
      >
        {!loadingButton && (
          <div className="modal-text-confirm">XÁC NHẬN TỪ CHỐI NHẬN ĐƠN</div>
        )}
      </Button>
      <div className="modal-button-container"></div>
      <Button
        className="modal-button-back"
        onClick={() => {
          closeModalRejectOrder();
          setSelectedRadio(null);
          setNote("");
          returnModal();
        }}
      >
        <div className="modal-text-back">
          <img
            src={backIcon}
            style={{ width: "16px", height: "16px", marginRight: 5 }}
          ></img>
          <div style={{ fontWeight: "bold" }}>QUAY LẠI</div>
        </div>
      </Button>
    </div>
  );
  const closeIcon = (
    <span>
      <CloseCircleFilled
        style={{
          color: "#e31837",
          marginLeft: "70px",
          fontSize: 46,
          backgroundColor: "#ffffff",
          borderRadius: 50,
        }}
      />
    </span>
  );

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      zIndex={1000}
      footer={footer}
      className="modal-container modal-reject-order"
      title={title}
      visible={isShowModalRejectOrder}
      onCancel={() => {
        closeModalRejectOrder();
        setSelectedRadio(null);
        setNote("");
      }}
      closeIcon={closeIcon}
    >
      <div className="modal-reject-flex" onClick={() => {
        setSelectedRadio(1);
        setComment("Đơn hàng không khả thi");
      }}>
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>
            Đơn hàng không khả thi
          </div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 1} />
          </span>
        </div>
      </div>
      <div className="modal-reject-flex" onClick={() => {
        setSelectedRadio(2);
        setComment("Bom");
      }}>
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>Bom</div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 2} />
          </span>
        </div>
      </div>
      <div className="modal-reject-reason" onClick={() => setSelectedRadio(3)}>
        <div className="modal-reject-text">
          <div style={{ fontWeight: "bold", font: 16 }}>Lý do khác</div>
        </div>
        <div className="modal-reject-radio">
          <span>
            <Radio checked={selectedRadio === 3} />
          </span>
        </div>
      </div>
      {selectedRadio === 3 && (
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={4}
          placeholder="Vui lòng nhập lý do"
          style={{ borderRadius: 10, padding: 10 }}
        />
      )}

    </Modal>
  );
};
