import React from "react";
import ImgMoto from "../../assets/motorcycle.png";
import ImgshopBig from '../../assets/shopbig.png';
function Icon(props) {
  const {status} = props;
  return (
    <div className="icon-car">
      <img src={status ? ImgMoto : ImgshopBig} alt=""></img>
   </div>
  )
}

export default Icon;
