import _ from 'lodash';
import React, { createContext, useEffect } from 'react';
import PopupNetwork from "./PopupNetwork";
import "./index.scss";

export const NetworkContext = createContext({});


const NetworkProvider = ({ children }) => {
    const refPopupNetwork = React.useRef();

    useEffect(() => {
        const offline = window.addEventListener('offline', function (e) {
            refPopupNetwork.current?.show();
        });

        const online = window.addEventListener('online', function (e) {
            refPopupNetwork.current?.hide();
        });

        return () =>{
            offline();
            online();
        };
    }, []);


    const onClosePopup = () => {
      window.location.reload();
    };


    return (
        <NetworkContext.Provider
            value={{}}>
            {children}
            <PopupNetwork
                ref={refPopupNetwork}
                onClosePopup={onClosePopup}
            />
        </NetworkContext.Provider>
    );
};

export default NetworkProvider;
