import { CloseCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { customNotification } from "utils/CommomFunction";
import { OrderTransferModal } from "../../components/Modal/OrderTransferModal";
function ButtonGr(props) {
  const { dataOrder, handleClick, cancelCurrentModal, setShowModalTransfer } = props;

  const handleShowModal = () => {
    if (dataOrder?.shipping_method === "Nhận tại cửa hàng") {
      return customNotification("error", "Đơn hàng pickup không được chuyển");
    }
    if (dataOrder?.assign_from?.name) {
      return customNotification("error", "Đơn hàng chỉ được chuyển 1 lần");
    }
    cancelCurrentModal();
    setShowModalTransfer(true);
  };
  const handleClickReject = () => {
    handleClick()
  }
  return (
    <div className="group-button" style={{ marginTop: '5px', paddingBottom: '5px', display: "flex", alignItems: "center", justifyContent: "center" }}>
      <button key="1" onClick={handleClickReject}>
        <CloseCircleOutlined />
        TỪ CHỐI
      </button>
      {/* <span className="border-center"></span>
      <button key="2" onClick={handleShowModal}>
        <RightCircleOutlined />
        CHUYỂN
      </button> */}
    </div>
  );
}

export default ButtonGr;
