import React from 'react';
import { Input } from "antd";
import "./index.scss";

const CustomInput = ({
    value = "",
    onChange = () =>{},
    ...props
}) => {
    return <Input className='customInput' value={value} onChange={onChange} {...props} />
};

export { CustomInput };