import { callApi } from "..";

export const apiLogin = (data) =>
  callApi(
    `mutation ($username: String!,$password: String!, $fcmToken: String!) {
    generateStaffToken(username: $username,password: $password,fcmToken: $fcmToken) {
      token
      firstname
      lastname
      address
      restaurant
      id
      accept_order
      store_status
      weekday
      weekend
    }
  }`,
    data,
    false
  );


export const apiLogout = (data) =>
  callApi(
    `mutation ($fcmToken: String!) {
      revokeStaffToken(fcmToken: $fcmToken) {
          result
        }
      }`,
    data,
  );
