import { callApi } from "..";

export const apiChangeStoreStatus = (data) => callApi(
  `mutation (
    $status: Int!
    $weekday: String!
    $weekend: String!
  ) {
    storeStatus(
        status: $status
        weekday: $weekday
        weekend: $weekend
    ) {
        result
    }
}` ,
  data
)
export const apiAttendance = () => callApi(
  `
  mutation{
    dailyAttendance {
      result
    }
  }
  `,
  null
);