import React from "react";
import ImgMaintenance from "../../assets/maintenance.png";
import "./index.scss";

function Maintenance(props) {
  return (
    <div className="maintenance">
      <img src={ImgMaintenance} alt=""></img>
      <h3>HỆ THỐNG ĐANG ĐƯỢC BẢO TRÌ</h3>
      <h5>Thành thật Xin lỗi bạn vì sự bất tiện này. Vui lòng quay lại lần sau, Xin cảm ơn!</h5>
    </div>
  )
}

export default Maintenance;
