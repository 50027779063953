import { Button, Modal } from "antd";
import React from "react";
import jollibee_avatar from "assets/jollibee_avatar.png";
import "./index.scss";

function ModalWarningComplete(props) {
    const { isShowModal, closeModal, messageWarningComplete = "", isPickup = false } = props;

    return (
        <Modal
            centered={true}
            destroyOnClose={true}
            className="modal-delivered modal-warning-complete"
            visible={isShowModal}
            width={410}
            onCancel={closeModal}
            closable={false}
            footer={[
                <Button
                    className="btn-primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        closeModal();
                    }}
                >
                    {"ĐÓNG"}
                </Button>,
            ]}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <img src={jollibee_avatar} style={{ width: 140, height: 140 }} />
                <h4 style={{ fontSize: 28, fontWeight: "bold", color: "#D0343E" }}>
                    Thông báo
                </h4>
                <p style={{ textAlign: "center", marginBottom: 16, marginTop: 8, fontSize: 14 }}>
                    {!isPickup ? `Đơn hàng sẽ được bấm Hoàn Thành sau ${messageWarningComplete} phút tính từ lúc chuyển sang Đang Giao` :
                        `Đơn nhận tại cửa hàng sẽ được bấm Hoàn Thành sau ${messageWarningComplete} phút tính từ lúc chuyển sang Đang Nấu`
                    }
                </p>
            </div>
        </Modal>
    );
}

export default ModalWarningComplete;
