import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes";
import {
  firebaseMessaging,
  requestFirebaseNotificationPermission,
} from "./firebaseInit";
import NetworkProvider from "./components/NetworkProvider";
import "./ReactotronConfig";

const App = (props) => {
  useEffect(() => {
    if (firebaseMessaging) {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          localStorage.setItem("fcmToken", firebaseToken);
        })
        .catch((err) => {
          return err;
        });
    }
    firebaseMessaging.getToken({
      vapidKey:
        "BHvwoHJ48Nr-9gLJqGbrFyve1HSwSxFkSaBIYMeq9T1mQ-8B_H-jDpQp0TvXClPHFrMkkejlDOhkOwrbIFTEM2A",
    }).then(firebaseToken => {
      localStorage.setItem("fcmToken", firebaseToken);
    })
  }, []);

  return (
    <NetworkProvider>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </NetworkProvider>
  );
};

export default App;
