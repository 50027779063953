import { Modal } from "antd";
import React from "react";
import ButtonSubmit from "../../components/Button/ButtonSubmit";
import iconCooking from "../../assets/cooking.png";

const ModalReceived = ({
    renderTitleModal,
    isShowModalRecieved,
    closeModalRecieved,
    dataOrderModal,
    handleSubmitCookingOrder,
    loadingButton,
    renderModalData,
    detailOrder,
}) => {
    return (
        <Modal
            key="received"
            centered={true}
            destroyOnClose={true}
            className="modal-recieved"
            title={renderTitleModal()}
            visible={isShowModalRecieved}
            width={900}
            onCancel={closeModalRecieved}
            footer={[
                <span key={dataOrderModal?.id} onClick={() => handleSubmitCookingOrder(dataOrderModal?.id)}>
                    <ButtonSubmit
                        loadingButton={loadingButton}
                        title="ĐANG NẤU"
                        key={2}
                        image={iconCooking}
                        color="#F0810D"
                        classname="btn-cooking"
                    />
                </span>,
                // <ButtonGr key={2} />,
            ]}
        >
            {renderModalData(detailOrder, dataOrderModal)}
        </Modal>
    );
};

export default ModalReceived;
