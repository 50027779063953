import React from "react";
import "./index.scss";
import iconClock from "../../assets/clock.png";
import moment from "moment";
import Countdown from "react-countdown";
import { isEmpty } from "lodash";

function ButtonTime(props) {
  const { dataOrder } = props;
  const { pickup_date, pickup_time } = dataOrder || {};

  const pickup_estimate = moment(pickup_date, ["YYYY-MM-DD hh:mm:ss"])
    .format("YYYY-MM-DD") + " " + pickup_time;

  const diff = moment(
    pickup_estimate, ["YYYY-MM-DD hh:mm A"])
    .diff(moment(dataOrder?.created_at), "minutes");

  const timeToEnd = moment(dataOrder?.created_at)
    .add(Number(
      isEmpty(pickup_date) ? dataOrder?.delivery_time : diff), "m")
    .toDate();

  const renderer = ({ hours, minutes, seconds, days }) => {
    return (
      <button className="button button-time">
        {
          days > 0 ? `${days} ngày` :
            <>
              <img
                src={iconClock}
                alt=""
                style={{ width: "12px", height: "12px" }}
              ></img>
              <b>
                {hours > 0 ? `${hours}:` : ``}{minutes > 9 ? minutes : `0${minutes}`}:{seconds > 9 ? seconds : `0${seconds}`}
              </b>
            </>
        }
      </button>
    );
  };
  return (
    <Countdown key={dataOrder?.id} date={timeToEnd} renderer={renderer} />
  );
}

export default ButtonTime;
