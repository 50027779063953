import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyCroh4YEvOo6aoQIdcuwT_ezyoSYzOny1c",
  authDomain: "delivery-jollibee.firebaseapp.com",
  databaseURL: "https://delivery-jollibee.firebaseio.com",
  projectId: "delivery-jollibee",
  storageBucket: "delivery-jollibee.appspot.com",
  messagingSenderId: "320365913986",
  appId: "1:320365913986:web:a57ad96431ba760562919b",
  measurementId: "G-X41HD11MC4",
};

let firebaseMessaging = null;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config);
  firebaseMessaging = firebase.messaging();
}

export { firebaseMessaging };

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    Notification.requestPermission()
      .then(() =>
        firebaseMessaging.getToken({
          vapidKey:
            "BHvwoHJ48Nr-9gLJqGbrFyve1HSwSxFkSaBIYMeq9T1mQ-8B_H-jDpQp0TvXClPHFrMkkejlDOhkOwrbIFTEM2A",
        })
      )
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
