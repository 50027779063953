import { Modal } from "antd";
import React from "react";

const ModalReadyToShip = ({
    renderTitleModal,
    isShowModalReadyShip,
    closeModalReadyShip,
    footer,
    renderModalData,
    detailOrder,
    dataOrderModal
}) => {
    return (
        <Modal
            key="readytoship"
            centered={true}
            destroyOnClose={true}
            className="modal-ready-ship"
            title={renderTitleModal()}
            visible={isShowModalReadyShip}
            width={900}
            onCancel={closeModalReadyShip}
            footer={footer}
        >
            {renderModalData(detailOrder, dataOrderModal)}
        </Modal>
    );
};

export default ModalReadyToShip;
